import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkBox from "../components/tools/LinkBox"

const Calculators = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const image = name => data.allImageSharp.nodes.find(node => node.fixed.originalName === name).fixed

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Lottery Calculators And Other Useful Tools" />
      <h1>Free Lottery Tools Calculators</h1>
      <LinkBox link="/lottery-number-generator/" name="Lottery Number Generator" img={image("computer.jpg")} linkText="TRY IT FOR FREE"
               description="Simulate your own lottery draw, or generate your next set of numbers to play. Easy to use, fast, secure and guaranteed to be random. Based on cutting-edge cryptographic algorithms." />
      <LinkBox link="/lottery-tax-calculator/" name="Lottery Tax Calculator" img={image("check.jpg")} linkText="TRY IT FOR FREE"
               description="How much tax will you have to pay on your lottery winnings? You'll probably be quite suprised by the results. This tool helps you get an estimation based on your location." />
      <LinkBox link="/best-odds/" name="Lottery Odds Comparision" img={image("choice.jpg")} linkText="TRY IT FOR FREE"
               description="Which lottery is the easiest to win? We have built a database that contains the odds for all the lottery games around the world. You can filter them by country and compare them with each other to find out which lottery games have the best odds in your area." />
      <LinkBox link="/lottery-odds-calculator/" name="Lottery Odds Calculator" img={image("blackboard.jpg")} linkText="TRY IT FOR FREE"
               description="Want to know what are your odds to win the jackpot or a smaller prize? This is the tool for you! Calculate the odds of hundreds of games - or set your own custom parameters for a theoretical lottery game." />
      <LinkBox link="/lottery-annuity-calculator/" name="Annuity Payout Calculator" img={image("coins.jpg")} linkText="TRY IT FOR FREE"
               description="Which one should you choose? Annuity payout or lump sum? What will be the payouts for a certain prize if you go with annuity? See for yourself!" />
      <LinkBox link="/expected-value-calculator/" name="Expected Value Calculator" img={image("investment.jpg")} linkText="TRY IT FOR FREE"
               description="Is it worth playing the lottery? What is the ROI of buying a lottery ticket? Is there a limit where the jackpot is big enough where it actually makes sense to put your money into lottery tickets? Does your chances grow if you buy more tickets?" />
    </Layout>
  )
}

export default Calculators

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allImageSharp {
      nodes {
        fixed(width: 300, height: 225) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
          originalName
        }
      }
    }
  }
`
